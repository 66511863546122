import axios from "axios";
import Swal from "sweetalert2";

const baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  config.baseURL = baseURL;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status == 423) {
      Swal.fire({
        title: "Info",
        text: "Session Habis, Silahkan Login Kembali",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
      });
      setTimeout(() => {
        Swal.close();
        localStorage.removeItem("userData");
        window.location.reload();
      }, 3000);
    }

    if (status == 426) {
      Swal.fire({
        title: "Info",
        text: "Versi Baru Tersedia, Refresh Halaman",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
      });
      setTimeout(() => {
        Swal.close();
        caches
          .keys()
          .then(function (names) {
            for (let name of names) {
              caches.delete(name);
            }
          })
          .then(() => {
            window.location.reload();
          });
      }, 3000);
    }

    if (status == 429) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
