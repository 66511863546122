import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Home from "../pages/Home.vue";

const routes = [
  {
    path: "/",
    component: () => import("../pages/Home.vue"),
  },
  {
    path: "*",
    component: () => import("../pages/NotFound.vue"),
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/forbidden",
    component: () => import("../pages/Forbidden.vue"),
  },
  {
    path: "/ticket",
    component: () => import("../pages/ticket/List.vue"),
  },
  {
    path: "/ticket/open-ticket",
    component: () => import("../pages/ticket/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/ticket/report/:id",
    component: () => import("../pages/ticket/Report.vue"),
  },
  {
    path: "/ticket/process/:id",
    component: () => import("../pages/schedule/Process.vue"),
    meta: {
      requiredLevel: ["Administrator", "Engineer", "Koordinator", "SPV"],
    },
  },
  {
    path: "/schedule/preventive",
    component: () => import("../pages/schedule/List.vue"),
    meta: {
      requiredLevel: ["Administrator", "Engineer", "Koordinator", "SPV"],
    },
  },
  {
    path: "/schedule/preventive/create",
    component: () => import("../pages/schedule/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/schedule/preventive/process/:id",
    component: () => import("../pages/schedule/Process.vue"),
    meta: {
      requiredLevel: ["Administrator", "Engineer", "Koordinator", "SPV"],
    },
  },
  {
    path: "/report/ticket",
    component: () => import("../pages/report/ticket/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/team",
    component: () => import("../pages/master/team/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/team/create",
    component: () => import("../pages/master/team/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/team/update/:id",
    component: () => import("../pages/master/team/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/area",
    component: () => import("../pages/master/area/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/maintenance",
    component: () => import("../pages/master/maintenance/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/maintenance/create",
    component: () => import("../pages/master/maintenance/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/maintenance/update/:id",
    component: () => import("../pages/master/maintenance/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/karyawan",
    component: () => import("../pages/master/karyawan/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/karyawan/create",
    component: () => import("../pages/master/karyawan/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/karyawan/update/:id",
    component: () => import("../pages/master/karyawan/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/formchecklist",
    component: () => import("../pages/master/formchecklist/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/formchecklist/create",
    component: () => import("../pages/master/formchecklist/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/formchecklist/update/:id",
    component: () => import("../pages/master/formchecklist/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/lokasi",
    component: () => import("../pages/master/lokasi/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/lokasi/create",
    component: () => import("../pages/master/lokasi/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/lokasi/update/:id",
    component: () => import("../pages/master/lokasi/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/sub-lokasi",
    component: () => import("../pages/master/sublokasi/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/sub-lokasi/create",
    component: () => import("../pages/master/sublokasi/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/sub-lokasi/update/:id",
    component: () => import("../pages/master/sublokasi/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/equipment",
    component: () => import("../pages/master/equipment/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/device",
    component: () => import("../pages/master/device/List.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/device/create",
    component: () => import("../pages/master/device/Form.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/master/device/update/:id",
    component: () => import("../pages/master/device/Update.vue"),
    meta: {
      requiredLevel: "Administrator",
    },
  },
  {
    path: "/home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  const userAccess = isLoggedIn ? JSON.parse(isLoggedIn) : null;
  const userLevel = userAccess ? userAccess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    !isLoggedIn &&
    to.path !== "/login" &&
    !to.path.includes("/ticket/report/")
  ) {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && !requiredLevel.includes(userLevel)) {
    next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
  } else {
    next();
  }
});

export default router;
