export default {
  data() {
    return {
      userData: null,
      userId: null,
      userFullName: null,
      userEmail: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
      // url: "http://localhost/api-crm-dea",
      url: "https://sandbox-crm.dea.co.id/api",
    };
  },
  created() {
    const userData = localStorage.getItem("userData");
    if (userData) {
      this.userData = JSON.parse(userData);
      this.userId = this.userData.id;
      this.userFullName = this.userData.nama;
      this.userEmail = this.userData.email;
      this.userLevel = this.userData.level;
      this.userAccess = this.userData.hak_akses;
      this.userToken = this.userData.token;
      // this.userPhoto = this.userData.foto;
    }
  },
};
