<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-laptop", title: "Dashboard" },
  { path: "/ticket", icon: "fa fa-file", title: "Ticket" },
  {
    path: "/schedule",
    icon: "fa fa-calendar",
    title: "Schedule",
    children: [{ path: "/schedule/preventive", title: "Preventive Schedule" }],
  },
  {
    path: "/report",
    icon: "fa fa-file-pdf",
    title: "Report",
    children: [{ path: "/report/ticket", title: "Ticket Closed" }],
  },
  {
    path: "/master",
    icon: "fa fa-database",
    title: "Data Master",
    children: [
    { path: "/master/equipment", title: "Data Equipment" },
    { path: "/master/area", title: "Data Area" },
      { path: "/master/lokasi", title: "Data Lokasi" },
      { path: "/master/sub-lokasi", title: "Data Sub Lokasi" },
      { path: "/master/device", title: "Data Device" },
      { path: "/master/maintenance", title: "Data Maintenance" },
      // { path: "/master/preventive", title: "Data Preventive" },
      { path: "/master/formchecklist", title: "Data Preventive Checklist" },
      // { path: "/master/customer", title: "Data Customer" },
      { path: "/master/karyawan", title: "Data Karyawan" },
      { path: "/master/team", title: "Data Team" },
    ],
  },
];

export default sidebarMenu;
</script>
