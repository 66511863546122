<template>
  <ul class="nav">
    <li class="nav-profile">
      <a href="javascript:;" v-on:click="expand()">
        <div class="info text-center">
          {{ this.userFullName }}
          <small>{{ this.userLevel }}</small>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import userData from "../../plugins/userData";

export default {
  name: "SidebarNavProfile",
  mixins: [userData],
  data() {
    return {
      stat: "",
      pageOptions: PageOptions,
    };
  },
  methods: {
    expand: function () {
      this.stat = this.stat == "expand" ? "collapse" : "expand";
    },
  },
};
</script>
